import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const MissingComponent = props => {
  const {
    helpSubtitle,
    helpDescription,
    helpLink,
    agreedScreenshots,
    isEnd,
    isSecond,
    isLanding,
    isLandingPage,
    isDeiLanding,
    SI,
    isLead,
  } = props;
  return (
    <div className="full-container">
      <div className={`help-section ${isEnd ? 'help-section-end' : ''}`}>
        <div className="row">
          <div
            className={` col-md-6 order-1 help-section-text-col order-md-0  ${
              isSecond ? 'order-lg-1 ' : ''
            }`}
          >
            <p
              className={`help-section-subtitle ${
                isLanding ? 'help-section-subtitle-landing' : ''
              }`}
            >
              {helpSubtitle}
            </p>
            <p
              className={`help-section-description-si ${
                isLanding ? 'help-section-description-landing' : ''
              }`}
              dangerouslySetInnerHTML={{
                __html: helpDescription,
              }}
            ></p>
            {!isLandingPage && (
              <Link className="help-section-link" to="/contact">
                {helpLink}
              </Link>
            )}
          </div>
          <div
            className={`col-md-6 order-0 order-md-1 ${
              isSecond ? 'order-lg-0 ' : ''
            }`}
            // style={{ textAlign: 'end' }}
          >
            <div
              className={`help-section-image-section ${
                isSecond ? 'help-section-image-section-landing' : ''
              }`}
            >
              <GatsbyImage
                alt={''}
                image={agreedScreenshots}
                className={`help-section-image  ${
                  SI ? 'help-section-image-landing-si' : ''
                } ${isLead ? 'help-section-image-lead' : ''}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MissingComponent;
