import React from 'react';
import BannerImage from '../../images/bannerProcess.png';
import ProcessBannerSecondImage from '../../images/processBannerSecond.png';
const BannerProcessLandingPageComponent = props => {
  const { isFirst, isSecond } = props;
  return (
    <div className="banner-process-section" style={{ textAlign: 'center' }}>
      {isFirst && (
        <>
          <div className="full-container">
            <p
              style={{
                fontSize: '36px',
                fontWeight: '300',
                fontFamily: 'avenir',
                maxWidth: '1000px',
                width: '100%',
                margin: '0 auto',
              }}
            >
              Agreed supports the ongoing change management process throughout
              your cycle.
            </p>
          </div>
          <img
            src={BannerImage}
            className="banner-process-section-image"
            style={{ width: '100%', marginTop: '77px' }}
          />
        </>
      )}
      {isSecond && (
        <img
          src={ProcessBannerSecondImage}
          style={{ width: '100%', marginTop: '60px', marginBottom: '60px' }}
        />
      )}
    </div>
  );
};
export default BannerProcessLandingPageComponent;
