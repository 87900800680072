import React from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { GatsbyImage } from 'gatsby-plugin-image';
const CompaniesComponent = props => {
  const {
    companiesTitle,
    companiesDescription,
    panasonicImage,
    humanImage,
    bankOfAmericaImage,
    mellonImage,
    mufgImage,
    nyseImage,
    pncImage,
    isSI,
  } = props;
  return (
    <div className={`companies-section ${isSI ? 'companies-section-si' : ''}`}>
      <div className="full-container">
        <div className="companies-section-wrapper">
          <h5
            className={`companies-section-title ${
              isSI ? 'companies-section-title-si' : ''
            }`}
          >
            {companiesTitle}
          </h5>
          <p className="companies-section-description">
            {companiesDescription}
          </p>
        </div>
        <Swiper
          spaceBetween={0}
          slidesPerView={'3'}
          speed={1500}
          modules={[Autoplay]}
          centeredSlides
          autoplay={{
            delay: 1,
            disableOnInteraction: true,
          }}
          breakpoints={{
            768: {
              slidesPerView: 6,
            },
          }}
          loop
        >
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <SwiperSlide>
                <GatsbyImage
                  imgStyle={{ width: '100%' }}
                  image={panasonicImage}
                  alt={''}
                  className="companies-section-image"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  imgStyle={{ width: '100%' }}
                  image={humanImage}
                  alt={''}
                  className="companies-section-image"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  imgStyle={{ width: '100%' }}
                  image={bankOfAmericaImage}
                  alt={''}
                  className="companies-section-image"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  imgStyle={{ width: '100%' }}
                  image={mellonImage}
                  alt={''}
                  className="companies-section-image"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  imgStyle={{ width: '100%' }}
                  image={mufgImage}
                  alt={''}
                  className="companies-section-image"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  imgStyle={{ width: '100%' }}
                  image={nyseImage}
                  alt={''}
                  className="companies-section-image"
                />
              </SwiperSlide>
              <SwiperSlide>
                <GatsbyImage
                  imgStyle={{ width: '100%' }}
                  image={pncImage}
                  alt={''}
                  className="companies-section-image"
                />
              </SwiperSlide>
            </div>
          </div>
        </Swiper>
        {/* {isSI && (
            <div className="companies-section-steps-wrapper">
              <div className="companies-section-steps-rectangle consider">
                <p className="companies-section-steps-title consider">
                  Consider
                </p>
                <p className="companies-section-steps-description consider">
                  New Ideas
                </p>
              </div>
              <div className="companies-section-steps-rectangle agree">
                <p className="companies-section-steps-title agree">Agree</p>
                <p className="companies-section-steps-description agree">
                  On the details
                </p>
              </div>
              <div className="companies-section-steps-rectangle launch">
                <p className="companies-section-steps-title launch">Launch</p>
                <p className="companies-section-steps-description launch">
                  Successful initiatives
                </p>
              </div>
            </div>
          )} */}
      </div>
    </div>
  );
};
export default CompaniesComponent;
