import React from 'react';
import Header from '../components/headerComponent';
import AgreedHelpComponent from '../components/homeCompnent/agreedHelpComponent';
import CompaniesComponent from '../components/homeCompnent/companiesComponent';
import HeaderComponentLeader from '../components/landingComponents/headerComponentLeader';
import LandingPageDEIComponent from '../components/landingComponents/landingPageDEIComponent';
import RequestADemoComponent from '../components/landingComponents/requestADemoComponent';
import NewFooterComponent from '../components/newFooterComponent';
import Layout from '../components/layout';
import SiImage from '../images/formImage.png';
import { graphql } from 'gatsby';
import ThoughtSlideComponent from '../components/landingComponents/thoughtSlideComponent';
import DemoImage from '../images/requestADemo.png';
import Test from '../images/images.png';
import BannerProcessLandingPageComponent from '../components/landingComponents/bannerProcessLandingPageComponent';
import MissingComponent from '../components/landingComponents/missingComponent';
import CompanyStaticComponent from '../components/landingComponents/companyStaticComponent';

const SystemImplementationSoftware = props => {
  const {
    data: {
      wpPost: {
        seo,
        landingPageLeadSection: {
          leadSubtitle,
          leadTitle,
          leadImage: {
            localFile: {
              childrenImageSharp: [{ gatsbyImageData: leadImage }],
            },
          },
        },
        agreedHelpSavingTimeSection: {
          savingTimeSectionDescription,
          savingTimeSectionTitle,
          savingTimeSectionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: savingTimeSectionImage },
            },
          },
        },
        newHomeCompaniesSection: {
          companiesSectionDescription,
          companiesSectionTitle,
          companiesSectionBankOfAmerica: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: companiesSectionBankOfAmerica,
              },
            },
          },
          companiesSectionFanniemae: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionFanniemae },
            },
          },
          companiesSectionHeinz: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionHeinz },
            },
          },
          companiesSectionHumana: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionHumana },
            },
          },
          companiesSectionMufg: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionMufg },
            },
          },
          companiesSectionNyse: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionNyse },
            },
          },
          companiesSectionPanasonic: {
            localFile: {
              childImageSharp: { gatsbyImageData: companiesSectionPanasonic },
            },
          },
        },
        landingPageListSection: {
          landingPageListText,
          landingPageListTitle,
          landingPageListImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: landingPageListSection },
            },
          },
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout seo={seo} title={title}>
      <Header />
      <HeaderComponentLeader
        teamworkTitle={leadTitle}
        teamworkDescription={leadSubtitle}
        isLandingPage={true}
        landingImage={leadImage}
        isLanding={true}
      />
      <BannerProcessLandingPageComponent isFirst={true} />
      <LandingPageDEIComponent landingDeiTitle="70% of digital transformation efforts fall short" />
      <div style={{ maxWidth: '1180px', width: '100%', margin: '0 auto' }}>
        <MissingComponent
          helpSubtitle={landingPageListTitle}
          helpDescription={landingPageListText}
          agreedScreenshots={landingPageListSection}
          isFirst={true}
          isSecond={false}
          isLandingPage={true}
          isDeiLanding={true}
          SI={true}
          //   isLanding={true}
        />
        <MissingComponent
          helpSubtitle={savingTimeSectionTitle}
          helpDescription={savingTimeSectionDescription}
          agreedScreenshots={savingTimeSectionImage}
          isSecond={true}
          isLandingPage={true}
          isDeiLanding={true}
          // isLanding={true}
          SI={true}
        />
      </div>
      <ThoughtSlideComponent />
      <CompanyStaticComponent
        companiesTitle={companiesSectionTitle}
        companiesDescription={companiesSectionDescription}
        panasonicImage={companiesSectionBankOfAmerica}
        humanImage={companiesSectionFanniemae}
        bankOfAmericaImage={companiesSectionHeinz}
        mellonImage={companiesSectionHumana}
        mufgImage={companiesSectionMufg}
        nyseImage={companiesSectionNyse}
        pncImage={companiesSectionPanasonic}
        isSI={true}
      />
      <BannerProcessLandingPageComponent isSecond={true} />
      <RequestADemoComponent
        isLanding={true}
        requestDemoTitle="Schedule a Demo"
        RequestADemoImage={Test}
        SI={true}
        // isDEILandingPage={true}
      />
      <NewFooterComponent isProduct={true} />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPost(slug: { eq: "system-implementation-software" }) {
      seo {
        metaDesc
        title
      }
      landingPageLeadSection {
        leadButton
        leadSubtitle
        leadTitle
        leadImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      agreedHelpSavingTimeSection {
        savingTimeSectionButtonText
        savingTimeSectionDescription
        savingTimeSectionTitle
        savingTimeSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      newHomeCompaniesSection {
        companiesSectionDescription
        companiesSectionTitle
        companiesSectionBankOfAmerica {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionFanniemae {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionHeinz {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionHumana {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionMufg {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionNyse {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        companiesSectionPanasonic {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      landingPageListSection {
        landingPageListText
        landingPageListTitle
        landingPageListImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default SystemImplementationSoftware;
