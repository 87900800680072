import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const CompanyStaticComponent = props => {
  const {
    companiesTitle,
    companiesDescription,
    panasonicImage,
    humanImage,
    bankOfAmericaImage,
    mellonImage,
    mufgImage,
    nyseImage,
    pncImage,
    isSI,
    isLead,
  } = props;
  return (
    <div
      className={`companies-section-si ${
        isLead ? 'companies-section-lead' : ''
      }`}
    >
      <div className="full-container">
        <div className="companies-section-wrapper">
          <h5 className={`${isSI ? 'companies-section-title-si' : ''}`}>
            {companiesTitle}
          </h5>
        </div>
        <div className="swiper-static-container">
          <GatsbyImage
            imgStyle={{ width: '244px', height: '42px', margin: 'auto 0' }}
            image={panasonicImage}
            alt={''}
            className="swiper-static-container-image"
          />

          <GatsbyImage
            imgStyle={{ width: '162px', height: '54px', margin: 'auto 0' }}
            image={humanImage}
            alt={''}
            className="swiper-static-container-image"
          />

          <GatsbyImage
            imgStyle={{ width: '60px', height: '60px', margin: 'auto 0' }}
            image={bankOfAmericaImage}
            alt={''}
            className="swiper-static-container-image"
          />

          <GatsbyImage
            imgStyle={{ maxWidth: '126px', height: '20px', margin: 'auto 0' }}
            image={mellonImage}
            alt={''}
            className="swiper-static-container-image"
          />

          <GatsbyImage
            imgStyle={{ width: '142px', height: '60px', margin: 'auto 0' }}
            image={mufgImage}
            alt={''}
            className="swiper-static-container-image"
          />

          <GatsbyImage
            imgStyle={{ width: '117px', height: '64px', margin: 'auto 0' }}
            image={nyseImage}
            alt={''}
            className="swiper-static-container-image"
          />

          <GatsbyImage
            imgStyle={{ width: '45px', height: '52px', margin: 'auto 0' }}
            image={pncImage}
            alt={''}
            className="swiper-static-container-image"
          />
        </div>
      </div>
    </div>
  );
};
export default CompanyStaticComponent;
