import React from 'react';
// import RequestADemoImage from '../../images/requestADemo.png';
import { useForm } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';
import { toast, ToastContainer } from 'react-toastify';
import { GatsbyImage } from 'gatsby-plugin-image';

const SUBMIT_CONTACT = gql`
  mutation contact($data: ContactUsFormInput!) {
    submitContact(data: $data) {
      errors
    }
  }
`;
const RequestADemoComponent = props => {
  const {
    requestDemoTitle,
    RequestADemoImage,
    isDEILandingPage,
    SI,
    isLanding,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
  });
  const [contact, { loading }] = useMutation(SUBMIT_CONTACT);
  const isLoading = loading;
  const onSubmitHandler = values => {
    const { username, firstName, lastName, phone, additionalText } = values;
    const data = {
      email: username,
      firstName,
      lastName,
      phone,
      additionalText,
    };
    contact({ variables: { data } })
      .then(response => {
        console.log(response);
        toast.success('Message sent successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      })
      .catch(errors => {
        console.log(errors);
        toast.error('There was a problem. Please check again later.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };

  return (
    <div
      className={`demo-section ${
        isDEILandingPage ? 'demo-section-landing' : ''
      } ${SI ? 'demo-section-landing ' : ''}`}
    >
      <div className="full-container">
        <h4 className="demo-section-title">{requestDemoTitle}</h4>
        <div className={`${SI ? 'landing-si ' : ''}`}>
          <div className="row">
            <div className={`col-md-6 ${isDEILandingPage ? 'order-md-2' : ''}`}>
              <div
                className={`demo-section-form ${
                  isDEILandingPage ? 'demo-section-landing-form' : ''
                } ${SI ? 'landing-si-form' : ''}`}
              >
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="form-group">
                    <label
                      htmlFor="firstName"
                      className="demo-section-form-label"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      disabled={isLoading}
                      placeholder="Full name"
                      className={`demo-section-form_control ${
                        errors.firstName ? 'is-invalid' : ''
                      }`}
                      {...register('firstName', {
                        required: 'Enter your first name!',
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors.firstName?.message}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="firstName"
                      className="demo-section-form-label"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      disabled={isLoading}
                      placeholder="Full name"
                      className={`demo-section-form_control ${
                        errors.lastName ? 'is-invalid' : ''
                      }`}
                      {...register('lastName', {
                        required: 'Enter your last name!',
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors.lastName?.message}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="firstName"
                      className="demo-section-form-label"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      disabled={isLoading}
                      placeholder="Email Address"
                      className={`demo-section-form_control ${
                        errors.username ? 'is-invalid' : ''
                      }`}
                      {...register('username', {
                        required: 'Enter your email address!',
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors.username?.message}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="firstName"
                      className="demo-section-form-label"
                    >
                      Phone
                    </label>
                    <input
                      type="number"
                      id="phone"
                      name="phone"
                      disabled={isLoading}
                      placeholder="Phone"
                      className={`demo-section-form_control ${
                        errors.phone ? 'is-invalid' : ''
                      }`}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="firstName"
                      className="demo-section-form-label"
                    >
                      Write your message
                    </label>
                    <textarea
                      rows={5}
                      type="text"
                      id="additionalText"
                      name="additionalText"
                      disabled={isLoading}
                      placeholder="Write your message..."
                      className={`demo-section-form_control ${
                        errors.additionalText ? 'is-invalid' : ''
                      }`}
                      {...register('additionalText')}
                    />
                  </div>
                  <button
                    type="submit"
                    className="demo-section-form-button"
                    aria-label="submit"
                  >
                    {!loading ? (
                      <>
                        Submit
                        {/* <IconButton className="form-section_button-icon" /> */}
                      </>
                    ) : (
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </form>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <ToastContainer />
              </div>
            </div>
            <div
              className={`col-md-6 ${
                isDEILandingPage ? 'order-md-1 demo-section-deiImage' : ''
              } ${SI ? 'landing-si-image' : ''} ${
                isLanding && 'change-managment-image'
              }`}
            >
              <div>
                <img
                  src={RequestADemoImage}
                  alt="requestDemoImage"
                  className="demo-section-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RequestADemoComponent;
