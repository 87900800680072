import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const ThoughtSlideComponent = () => {
  return (
    <div className="thought-slide-section">
      <Swiper
        pagination={true}
        modules={[Pagination]}
        spaceBetween={10}
        slidesPerView={1}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="thought-slide-section-wrapper">
            <p className="thought-slide-section-text">
              ‘’There are two leaders that drive success with buy-in and change
              management on the front-end: Deloitte & Accenture. And then
              there’s everyone else.’’
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="thought-slide-section-wrapper">
            <p className="thought-slide-section-text">
              ‘’Agreed is the missing link to help me and my consulting partners
              monetize our backlog’’
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
export default ThoughtSlideComponent;
